.landing-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .hero {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .hero h1 {
    font-size: 2.5rem;
    color: var(--primary-black);
  }
  
  .cta-button {
    background-color: var(--primary-black);
    color: var(--white);
    padding: 0.8rem 1.5rem;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: var(--secondary-black);
  }
  
  .features {
    margin-top: 3rem;
  }
  
  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .feature {
    background-color: var(--off-white);
    padding: 1.5rem;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .feature h3 {
    color: var(--secondary-black);
  }