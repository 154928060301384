.header {
    background-color: var(--primary-black);
    color: var(--white);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo a {
    color: var(--white);
    font-size: 1.5rem;
    text-decoration: none;
    font-weight: bold;
  }
  
  nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
  }
  
  nav ul li {
    margin-left: 20px;
  }
  
  nav ul li a {
    color: var(--white);
    text-decoration: none;
  }
  
  nav ul li a:hover {
    color: var(--light-gray);
  }