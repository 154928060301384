.resume-upload {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.resume-upload h2 {
  color: var(--primary-black);
  margin-bottom: 1rem;
}

.privacy-notice {
  background-color: var(--off-white);
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.privacy-notice h3 {
  color: var(--secondary-black);
  margin-top: 0;
}

.file-input {
  margin-bottom: 1rem;
  position: relative;
}

.file-input input[type="file"] {
  position: absolute;
  left: -9999px;
}

.file-label {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: var(--secondary-black);
  color: var(--white);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.privacy-agreement {
  margin-bottom: 1rem;
}

button[type="submit"] {
  background-color: var(--primary-black);
  color: var(--white);
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

button[type="submit"]:hover:not(:disabled) {
  background-color: var(--secondary-black);
}

button[type="submit"]:disabled {
  background-color: var(--medium-gray);
  cursor: not-allowed;
}